import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { ProjectSummary } from "../../components/projects/ProjectSummary";

const BrooksideSubdivision = () => (
  <Layout>
    <SEO
      title="Fox Street Subdivision"
      image={"/sub-divisions.jpg"}
      description="The scope of works consisted of installing new sewer, water, chorus and power to 11 lots, bulk earthworks, new right of way approx. 120 meters in length."
    />

    <div className="row g-0">
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        <div className="content-box">
          <h1 className="section-heading section-heading--left">
            Fox Street Subdivision
          </h1>
          <ProjectSummary
            name="Fox Street Subdivision"
            type="Subdivision"
            location="Featherston, Wairarapa"
            client="Private Residential Client"
            engineer="Tomlinson & Carruthers Surveyors Ltd"
          />
          <p>
            The scope of works consisted of installing new sewer, water, chorus
            and power to 11 lots, bulk earthworks, new right of way approx. 120
            meters in length. The installation of new rock soak pit, approx.
            250m3 in size. A new sewer pump station was installed as part of the
            work scope.
          </p>
        </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        <StaticImage
          aspectRatio={16 / 13}
          transformOptions={{ cropFocus: "center" }}
          objectPosition="50% 50%"
          src="../../images/sub-divisions.jpg"
          alt="Fox street subdivision"
        />
      </div>
    </div>
  </Layout>
);

export default BrooksideSubdivision;
